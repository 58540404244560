<template>
<b-overlay :show="unitLoading">
  <b-container fluid>
    <b-row class="text-right mt-2 mb-3">
                  <b-col>
                    <b-button @click="pdfExport()" class="btn btn-success btn-md">
                      <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
                    </b-button>
                  </b-col>
                </b-row>
       <b-row style="line-height: 20px">
            <template>
                        <b-row>
                                <b-col md="6"><b>{{$t('globalTrans.applicant_name')}}         </b> : {{ currentLocale === 'bn' ? authUser.name_bn : authUser.name }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.application_id')}}         </b> : {{ items.auto_id }} </b-col>
                                <b-col md="6"><b>{{$t('globalTrans.application_type')}}       </b> : {{ getApplicationType(items.application_type_id) }} </b-col>
                                <b-col md="6"><b>{{$t('globalTrans.email')}}                  </b> : {{ authUser.email }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.fiscal_year')}}            </b> : {{ getFiscalYear(items.fiscal_year_id) }}</b-col>
                                <b-col md="6" v-if="authUser.mobile"><b>{{$t('globalTrans.mobile')}}                 </b> : {{currentLocale == 'bn'? authUser.mobile.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d]) : authUser.mobile}}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.nid') }}                   </b> : {{ $n(profile.nid_no, { useGrouping: false }) }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.father_name')}}            </b> : {{ currentLocale === 'bn' ? profile.father_name_bn : profile.father_name_en }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.mother_name')}}            </b> : {{ currentLocale === 'bn' ? profile.mother_name_bn : profile.mother_name_en }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.company_name')}}            </b> : {{ currentLocale === 'bn' ? items.company_name_bn : items.company_name_en }}</b-col>
                                <b-col md="6"><b>{{$t('externalTradeTraiff.application_subject')}}            </b> : {{ currentLocale === 'bn' ? items.application_subject_bn : items.application_subject_en }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.gender')}}                 </b> : {{ profile.gender ? getGenderName(profile.gender) : '' }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.dob')}}                    </b> : {{ profile.dob | dateFormat }}</b-col>
                                <b-col md="6"><b>{{$t('externalUser.birth_place')}}           </b> : {{ currentLocale === 'bn' ? profile.birth_place_bn : profile.birth_place_en }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.nationality')}}            </b> : {{ currentLocale === 'bn' ? profile.nationality_bn : profile.nationality_en }}</b-col>
                                <b-col md="6"><b>{{$t('globalTrans.occupation')}}             </b> : {{ currentLocale === 'bn' ? profile.occupation_bn : profile.occupation_en }}</b-col>
                                <b-col md="6"><b>{{$t('eBizConfig.description')}}             </b> : {{ currentLocale === 'en'? items.description_en : items.description_bn}} </b-col>
                                 <b-col md="6"><b>{{$t('externalTradeTraiff.license_no')}}</b> : {{ items.license_no }} </b-col>
                                <b-col md="6"><b>{{ $t('externalTradeTraiff.bin_no')}}                </b> : {{ items.bin_no }} </b-col>
                                <!--<b-col md="6"><b>{{$t('tradeTariffApp.application_status')}}  </b> : {{ statusText(items.application_status) }} </b-col>-->
                                <hr>
                                <b-col md="4">{{ $t('globalTrans.main_attachment')}} :
                                    <span v-if="items.attachment_main !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.attachment_main"
                                            title="Attachment Main"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                    <span v-else>N/A</span>
                                </b-col>
                                <b-col md="4">{{ $t('globalTrans.bin_attachment') }} :
                                    <span v-if="items.bin_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.bin_attachment"
                                            title="Bin Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                    <span v-else>N/A</span>
                                </b-col>
                                <b-col md="4">{{ $t('globalTrans.license_attachment')}} :
                                    <span v-if="items.license_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.license_attachment"
                                            title="Bin Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                    <span v-else>N/A</span>
                                </b-col>
                            <hr>
                                <table class="table table-sm table-bordered section-tree-view-table">
                                      <thead>
                                          <tr>
                                              <th>{{ $t('teaGardenPanel.attachment_name') }}</th>
                                              <th>{{ $t('teaGardenPanel.attachment') }}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <slot v-for="(item, index) in items.add_more_files">
                                              <tr :key="index">
                                              <td>{{ currentLocale === 'bn' ? item.file_name_bn : item.file_name_en }}</td>
                                              <td>
                                                    <a target="_blank" style="margin-left: 8px;" v-if="item.attachment" :href="tradeTariffServiceBaseUrl  + item.attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                              </td>
                                              </tr>
                                          </slot>
                                      </tbody>
                                  </table>
                            <hr>
                            <table class="table table-sm table-bordered" v-if="items.application_status === 4 && items.application_status === 5">
                                <tbody>
                                    <tr>
                                        <th v-if="items.application_status === 4">{{ $t('tradeTariffApplication.approve') }} {{ $t('globalTrans.note') }}</th>
                                        <th v-if="items.application_status === 5" >{{ $t('globalTrans.archive') }} {{ $t('globalTrans.note') }}</th>
                                        <th>{{$t('globalTrans.attachment')}}</th>
                                    </tr>
                                    <tr>
                                        <td v-if="items.note_approve">
                                            <b>{{ $t('tradeTariffConfig.comment') }}: </b>
                                            {{ items.application_status === 5 ? getCommentName(items.archive_comment_id) : getCommentName(items.approve_comment_id)}} <br>
                                            <b>{{ $t('globalTrans.note') }} {{$t('globalTrans.details')}} : </b>
                                            {{items.note_approve}}
                                        </td>
                                        <td>
                                            <a target="_blank" style="margin-left: 8px;" v-if="items.attachment_approve" :href="tradeTariffServiceBaseUrl  + items.attachment_approve" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                            <a target="_blank" style="margin-left: 8px;" v-if="items.attachment_reject" :href="tradeTariffServiceBaseUrl  + items.attachment_reject" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>
            </template>
    </b-row>
  </b-container>
</b-overlay>
</template>
<script>
import AddressHelper from '@/utils/area-type-address'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { externalApplicationList } from '../../api/routes'
import { mapGetters } from 'vuex'
export default {
    props: ['items'],
    data () {
        return {
              AddressHelper: AddressHelper,
              tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
              unitLoading: false
            //   profile: this.$store.state.ExternalUserService.eBizPanel.commonObj.associationProfile
        }
    },
    components: {
    },
    created () {
    if (this.authUser && this.commonProfile) {
        this.profile = this.commonProfile
    }
    },
    mounted () {
        // core.index()
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        ...mapGetters({
            authUser: 'Auth/authUser',
            commonProfile: 'Auth/commonProfile'
        }),
        requestStatusList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Processing' : 'প্রক্রিয়াধীন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Processing' : 'প্রক্রিয়াধীন' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Agreement' : 'প্রত্যাখ্যাত' }
            ]
            return list
        },
        activeStatusList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Active' : 'সক্রিয়' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Inactive' : 'নিষ্ক্রিয়' }
            ]
            return list
        }
    },
    methods: {
     getCommentName (id) {
        const obj = this.$store.state.ExternalUserService.tradeTariff.commonObj.commentList.find(item => item.value === parseInt(id))
        if (obj) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        } else {
            return ''
        }
    },
    getFiscalYear (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    getDivisionName (Id) {
      const Obj = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === parseInt(Id))
      if (Obj) {
        return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
      } else {
        return ''
      }
    },
    getApplicationType (val) {
        const Obj = this.$store.state.ExternalUserService.tradeTariff.commonObj.applicationTypeList.find(el => el.value === val)
        if (Obj) {
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        } else {
            return ''
        }
    },
    getGenderName (val) {
        const Obj = this.$store.state.commonObj.genderList.find(el => el.value === val)
        if (Obj) {
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        } else {
            return ''
        }
    },
     getCouncilName (councilNameId) {
            const cateObj = this.$store.state.EBizManagementSystemService.commonObj.councilInfoList.filter(item => item.status === 1).find(item => item.value === councilNameId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
     },
      getAssociationName (associationId) {
          const cateObj = this.$store.state.EBizManagementSystemService.commonObj.associationInfoList.filter(item => item.status === 1).find(item => item.value === associationId)
          if (cateObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return cateObj.text_bn
              } else {
                  return cateObj.text_en
              }
          }
        },
        statusText (sta) {
            const reqStatusObj = this.requestStatusList.find(tlaw => tlaw.value === parseInt(sta))
            return reqStatusObj.text
        },
        activeStatus (sta) {
            const reqStatusObj = this.activeStatusList.find(tlaw => tlaw.value === parseInt(sta))
            return reqStatusObj.text
        },
        async pdfExport () {
            this.unitLoading = true
            const params = Object.assign({}, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7, created_by: this.items.created_by, data_id: this.items.id })
            const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, externalApplicationList, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            this.unitLoading = false
        }
    }
}

</script>
